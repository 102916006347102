import { styled } from 'Theme/stitches.config';

type PropTypes = {
  src: string;
  height?: string;
};

function Iframe({ src, height = '100%' }: PropTypes) {
  return (
    <IFrameWrapper style={{ height: height }}>
      <StyledIframe
        title="payment gateway"
        src={src}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </IFrameWrapper>
  );
}

const IFrameWrapper = styled('div', {
  w: '100%',
  maxWidth: '$videoMaxWidth',
  mx: 'auto',
  my: 'auto',
});

const StyledIframe = styled('iframe', {
  h: '100%',
  width: '100%',
});

export default Iframe;
