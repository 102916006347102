import { focusableElements } from 'DesignSystem/Accessibility/Utils';
import { animation, timings } from 'DesignSystem/Animations/animation';
import Heading from 'DesignSystem/Typography/Headings/Heading';
import { styled } from 'Theme/stitches.config';
import { useEffect, useId, useRef, useState } from 'react';

export type StepProps = {
  title: string;
  children: React.ReactNode;
  open: boolean;
  stepNumber: number;
  onStepChange?: (step: number, open: boolean, focus: boolean) => void;
};

function Step({ title, children, stepNumber, open, onStepChange }: StepProps) {
  const [isOpen, setOpen] = useState<boolean>(open);
  const [innerHeight, setInnerHeight] = useState<boolean>(open);
  const [isDisplayed, setDisplayed] = useState<boolean>(open);
  const rootRef = useRef<HTMLDivElement>(null);
  const headerId = useId();

  useEffect(() => {
    setInnerHeight(open);
    setOpen(open);
  }, [open]);

  useEffect(() => {
    if (isDisplayed) {
      const firstFocusableElement = rootRef.current?.querySelector(
        focusableElements
      ) as HTMLElement;

      // Set the focus to the first focusable element
      if (firstFocusableElement) {
        setTimeout(() => {
          firstFocusableElement.focus();
        }, 100);
      }
    }
  }, [isDisplayed]);

  useEffect(() => {
    const handleFocus = () => {
      // Call onStepChange with the appropriate parameters
      if (onStepChange) {
        onStepChange(stepNumber, true, true);
      }
    };
    const currentElement = rootRef.current;
    if (currentElement) {
      currentElement.addEventListener('focusin', handleFocus);
    }

    // Cleanup the event listener
    return () => {
      if (currentElement) {
        currentElement.removeEventListener('focusin', handleFocus);
      }
    };
  }, [rootRef]);

  useEffect(() => {
    if (open) {
      setOpen(true);
      setTimeout(() => {
        setDisplayed(true);
      }, 10); // Adjust delay as needed
    } else {
      setDisplayed(false);
      setTimeout(() => {
        setOpen(false);
      }, 300); // Adjust to match your opacity transition duration
    }
  }, [open]);

  return (
    <Root ref={rootRef} aria-labelledby={headerId}>
      <Heading tag="h2" size="s" id={headerId}>
        {title}
      </Heading>
      <ContentWrapper
        isDisplayed={isDisplayed}
        isOpen={isOpen}
        innerHeight={innerHeight}
      >
        {children}
      </ContentWrapper>
    </Root>
  );
}

const Root = styled('section', {
  pb: '$s250',
  transitionDuration: timings.threeTenths,
  transitionTimingFunction: animation.timingFn,
  transitionProperty: 'all',
});

const ContentWrapper = styled('div', {
  opacity: 0,
  maxHeight: 0,
  display: 'flex',
  flexDirection: 'column',
  transitionDuration: timings.threeTenths,
  transitionTimingFunction: animation.timingFn,
  transitionProperty: 'opacity height',
  variants: {
    isOpen: {
      true: {
        opacity: 1,
      },
      false: {},
    },
    innerHeight: {
      true: {
        maxHeight: 'unset',
        py: 2,
      },
    },
    isDisplayed: {
      true: { display: 'flex' },
      false: { display: 'none' },
    },
  },
});

export default Step;
