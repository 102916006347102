import { zodResolver } from '@hookform/resolvers/zod';
import PrimaryButton from 'DesignComponents/Atoms/Buttons/PrimaryButton';
import InputReactForm from 'DesignComponents/Atoms/Input/InputReactForm';
import ValidationResultModel from 'Models/Validation/ValidationResultModel.interface';
import { mapFluentValidationErrors } from 'Shared/Common/VariationHelpers';
import Button from 'Shared/Components/Buttons/Button';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { styled } from 'Theme/stitches.config';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { UpdateCustomerDetails } from '../Checkout';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import CheckoutPageModel from 'Models/Pages/CheckoutPage/CheckoutPageModel.interface';
import {
  CHECKOUT_UPDATE_FORM,
  EventDispatcher,
} from 'Shared/Common/EventDispatcher';
import ContactDetailsModel from 'Models/Checkout/CustomerDetails/ContactDetailsModel.interface';
import { debounce } from 'Shared/Common/debounce';

type PropTypes = {
  goToNextStep: (step: number, open: boolean) => void;
  active: boolean;
  open: boolean;
};

const MAX_EMAIL_LENGTH = 100;
const MAX_PHONENUMBER_LENGTH = 16;

function isValidDate(dateString: string | undefined) {
  if (!dateString) return true;

  const parts = dateString.split('-');
  if (parts.length !== 3) return false;

  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Month is 0-indexed in JavaScript Date
  const day = parseInt(parts[2], 10);

  // Check year and month are in valid range
  if (year < 1900 || year > new Date().getFullYear() || month < 0 || month > 11)
    return false;

  // Create a date object and check the day is valid for the given month and year
  const date = new Date(year, month, day);
  if (date.getDate() !== day) return false;

  // Check the date is not in the future
  if (new Date() < date) return false;

  // Check if the date is before 18 years ago
  const eighteenYearsAgo = new Date();
  eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
  if (date > eighteenYearsAgo) return false;

  return true;
}

const CustomerDetailsStep = ({ goToNextStep, active }: PropTypes) => {
  const { languageRoute } = useAppSettingsData();
  const { checkoutFormDataModel, isBirthDateInputEnabled } =
    useCurrentPage<CheckoutPageModel>();
  const formRef = useRef<HTMLFormElement>(null);
  const [editMode, setEditMode] = useState<boolean>(true);

  const {
    checkoutLabels: { email, phoneNumber, birthDate, goToDeliveryAddress },
    validationLabels: {
      invalidEmail,
      fillInEmail,
      invalidPhoneNumber,
      fillInPhoneNumber,
      invalidDate,
    },
  } = useTranslationData();

  const CustomerDetailsStepConfig = z.object({
    email: z
      .string()
      .min(1, { message: fillInEmail })
      .email({ message: invalidEmail }),
    phoneNumber: z
      .string()
      .min(8, { message: fillInPhoneNumber })
      .regex(/^(\+)?[0-9-\s]*$/, {
        message: invalidPhoneNumber,
      })
      .optional(),
    birthDate: z
      .string()
      .optional()
      .refine((data) => isValidDate(data), {
        message: invalidDate,
      }),
    shipping_country: z.string(),
  });

  interface CustomerDetailsStepConfigType
    extends z.infer<typeof CustomerDetailsStepConfig> {}

  const { formState, getValues, register, handleSubmit, setValue, trigger } =
    useForm<CustomerDetailsStepConfigType>({
      resolver: zodResolver(CustomerDetailsStepConfig),
      mode: 'onSubmit',
      defaultValues: {
        email: checkoutFormDataModel?.contactDetailsModel?.email,
        phoneNumber: checkoutFormDataModel?.contactDetailsModel?.phoneNumber,
        birthDate: checkoutFormDataModel?.contactDetailsModel?.birthDate,
        shipping_country: 'Sverige',
        // Add other form fields here...
      },
    });

  const [inValid, setInvalid] = useState<
    Record<keyof ContactDetailsModel, string>
  >({} as Record<keyof ContactDetailsModel, string>);

  const submit = () => {
    const payload = getValues();

    UpdateCustomerDetails(
      payload as unknown as ContactDetailsModel,
      languageRoute
    )
      .then((res) => {
        if (res && res.ok) {
          res.json().then((data: ValidationResultModel) => {
            if (!data.isValid && data.errors && Array.isArray(data.errors)) {
              //is invalid
              setInvalid(
                mapFluentValidationErrors<
                  Record<keyof ContactDetailsModel, string>
                >(data.errors)
              );
            } else {
              //is valid
              //setEditMode(false);
              EventDispatcher.dispatch(
                CHECKOUT_UPDATE_FORM,
                'contactDetailsModel',
                payload
              );
              goToNextStep(2, true);
            }
          });
        }
      })
      .catch(() => {
        // Unexpected error
        goToNextStep(2, true);
        // Todo: Implement better error handling
      });
  };

  const getEighteenYearsAgo = () => {
    const today = new Date();
    const eighteenYearsAgo = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate()
    );
    const year = eighteenYearsAgo.getFullYear();
    const month = String(eighteenYearsAgo.getMonth() + 1).padStart(2, '0'); // months are 0-indexed in JavaScript
    const day = String(eighteenYearsAgo.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const UpdateEntireForm = () => {
    //When fields are autofilled, react-hook-form does not update the form state
    //so we need to manually update the form state to reflect the current values in the form

    const form = formRef.current;
    if (form) {
      const formElements = form.elements;
      for (let i = 0; i < formElements.length; i++) {
        const element = formElements[i] as HTMLInputElement;
        if (element.name) {
          setValue(
            element.name as keyof CustomerDetailsStepConfigType,
            element.value
          );
          const value = getValues(
            element.name as keyof CustomerDetailsStepConfigType
          );
          if (value) {
            trigger(element.name as keyof CustomerDetailsStepConfigType);
          }
        }
      }
    }
  };

  const setValues = () => {
    debounce(() => {
      UpdateEntireForm();
    }, 1000);
  };

  return (
    <Root onSubmit={handleSubmit(submit)} ref={formRef}>
      {editMode && (
        <InputGroup>
          <InputReactForm
            type="email"
            name="email"
            title={email}
            placeholder={email}
            error={!!inValid['email'] || !!formState?.errors.email}
            errorText={
              inValid['email'] || formState
                ? formState.errors.email?.message
                : ''
            }
            register={register}
            required
            maxLength={MAX_EMAIL_LENGTH}
            autoComplete="email"
            onChange={setValues}
          />
          <InputReactForm
            title={phoneNumber}
            placeholder={phoneNumber}
            type="phone"
            name="phoneNumber"
            error={!!inValid['phoneNumber'] || !!formState?.errors.phoneNumber}
            errorText={
              inValid['phoneNumber'] || formState
                ? formState.errors.phoneNumber?.message
                : ''
            }
            register={register}
            required
            maxLength={MAX_PHONENUMBER_LENGTH}
            autoComplete="tel-national"
            onChange={setValues}
          />
          {isBirthDateInputEnabled && (
            <InputReactForm
              title={birthDate}
              error={!!inValid['birthDate'] || !!formState?.errors.birthDate}
              errorText={
                inValid['birthDate'] || formState
                  ? formState.errors.birthDate?.message
                  : ''
              }
              type="date"
              name="birthDate"
              register={register}
              autoComplete="bday"
              max={getEighteenYearsAgo()}
            />
          )}
        </InputGroup>
      )}

      {!editMode && (
        <div>
          {checkoutFormDataModel.contactDetailsModel?.email}
          <br />
          {checkoutFormDataModel.contactDetailsModel?.phoneNumber}
          <br />
          {checkoutFormDataModel.contactDetailsModel?.birthDate}
          <br />
          <button onClick={() => setEditMode(true)}>Redigera</button>
        </div>
      )}
      {active && editMode && (
        <Button<typeof PrimaryButton>
          element="PrimaryButton"
          props={{
            text: goToDeliveryAddress,
            color: 'Regular',
            hug: 'width',
          }}
          onClick={handleSubmit(submit)}
        />
      )}
    </Root>
  );
};

const Root = styled('form', {
  pt: 3,
  button: {
    marginTop: '$s200',
  },
});

const InputGroup = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$s100',
  '& div': {
    marginBottom: 0,
  },
});

export default CustomerDetailsStep;
