// ----------------------------------------------------------------------------------- //
// This is an auto generated file created by the Knowit.KEX.TypescriptGenerator.       //
// If changes has been made to the original .cs file that should be included,          //
// run the Knowit.KEX.TypescriptGenerator.exe to get the latest changes for the model. //
// ----------------------------------------------------------------------------------- //

enum CheckoutPaymentStatusEnum {
  InvalidCustomerDetails = 0,
  InProgress = 1,
  Complete = 2,
  Failed = 3,
}

export default CheckoutPaymentStatusEnum;
