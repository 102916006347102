import VariationModel from 'Models/KexVariation/VariationModel.interface';
import ValidationErrorModel from 'Models/Validation/ValidationErrorModel.interface';

export const GetVariantDescriptions = (variation: VariationModel) => {
  const descriptions: string[] = [];
  if (!variation) return undefined;
  return descriptions.join(', ');
};

export const mapFluentValidationErrors = <T extends { [key: string]: string }>(
  errors: ValidationErrorModel[],
  optionalSpliter?: string,
  replace?: string
) => {
  const errorMap: { [key: string]: string } = {};

  errors.forEach((error) => {
    let key = optionalSpliter
      ? error.propertyName.split(optionalSpliter)[1]
      : error.propertyName.split('.')[1];
    if (replace) {
      key = key.replace('.', replace);
    }
    errorMap[key] = error.errorMessage;
  });

  return errorMap as T;
};
