import { styled } from 'Theme/stitches.config';
import { useState } from 'react';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import ConfirmationStep from './Confirmation/ConfirmationStep';
import CustomerDetailsStep from './CustomerDetails/CustomerDetailsStep';
import DeliveryStep from './Delivery/DeliveryStep';
import Step from './Step';
import {
  CHECKOUT_SET_PAYMENT_STEP,
  EventDispatcher,
} from 'Shared/Common/EventDispatcher';

const CheckoutSteps = {
  1: {
    focus: true,
    open: true,
  },
  2: {
    focus: false,
    open: false,
  },
  3: { open: false, focus: false },
};

function CheckoutStepper() {
  const [steps, setSteps] = useState(CheckoutSteps);

  const {
    checkoutLabels: {
      contactInformation,
      deliveryAddress,
      termsAndConditionsText,
    },
  } = useTranslationData();

  const stepper = (step: number, open: boolean, focus = true) => {
    setSteps((prev) => {
      const updatedSteps = { ...prev };

      Object.keys(updatedSteps).forEach((key) => {
        // Convert the key back to a number if necessary
        const stepKey = parseInt(key, 10) as keyof typeof CheckoutSteps;

        if (stepKey === step) {
          updatedSteps[stepKey] = {
            ...updatedSteps[stepKey],
            open,
            focus,
          };
        } else {
          updatedSteps[stepKey] = {
            ...updatedSteps[stepKey],
            focus: false, // Set focus to false for all other steps
          };
        }
      });

      return updatedSteps;
    });
  };

  return (
    <Root>
      <Step
        stepNumber={1}
        onStepChange={stepper}
        title={`1. ${contactInformation}`}
        open={steps[1].open}
      >
        <CustomerDetailsStep
          open={steps[1].open}
          active={steps[1].focus}
          goToNextStep={stepper}
        />
      </Step>
      <Step
        stepNumber={2}
        onStepChange={stepper}
        title={`2. ${deliveryAddress}`}
        open={steps[2].open}
      >
        <DeliveryStep
          open={steps[2].open}
          active={steps[2].focus}
          goToNextStep={stepper}
        />
      </Step>
      <Step
        stepNumber={3}
        onStepChange={stepper}
        title={`3. ${termsAndConditionsText}`}
        open={steps[3].open}
      >
        <ConfirmationStep
          open={steps[3].open}
          active={steps[3].focus}
          goToNextStep={() => {
            EventDispatcher.dispatch(CHECKOUT_SET_PAYMENT_STEP, true);
          }}
        />
      </Step>
    </Root>
  );
}

const Root = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 40,
  section: {
    borderStyle: 'solid',
    borderColor: '$staticBorderDefault',
    borderBottomWidth: 1,
    'first-of-type': {
      borderTopWidth: 1,
    },
    'last-of-type': {
      borderBottomWidth: 0,
    },
  },
});

export default CheckoutStepper;
